import 'es6-promise/auto'
import Vue from 'vue'
import i18n from './i18n'
import App from './App'
import router from './router'
import vuetify from './plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css'
import './assets/scss/overrides.scss'
import store from '@/store'
import { PRINCIPLE_GET_ASYNC } from '@/store/action-types'

(async () => {
  // We need to pull user authorities and other info so do that first in case the page is being refreshed.
  // If this is ever removed, we'll have to make sure to update anything that uses PRINCIPLE_GET_USER when initializing.
  await store.dispatch(PRINCIPLE_GET_ASYNC)

  await import('./setup')

  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    router,
    components: { App },
    render: h => h(App),
    vuetify,
    i18n
  })
})()
