import { SplitFactory } from '@splitsoftware/splitio'
import store from '@/store'
import {
  ADD_TREATMENT,
  APP_SET_APPLICATION_SPLIT_IS_INITIALIZED,
  APP_SET_SPLIT_IS_INITIALIZED
} from '@/store/action-types'
import { GET_SINGLE_TREATMENT, GET_TREATMENT_ENABLED } from '@/store/get-types'
import LocalFeatures from './local-features'
import {
  API_KEY_MANAGEMENT,
  ENABLE_CART_BRAND_VIEW,
  KBP_BRANDS_BYPASS_EMAIL,
  KBP_BRANDS_QR_CODE,
  MOBILE_DELIVERY_MVP,
  OAUTH_CLIENT_CRED_MGMT,
  ORDER_SETTINGS,
  POR_1968_RG_REFERRAL_PROGRAM,
  PORTAL_LINE_ITEMS_SHOW_REMAINING_BALANCE,
  QUALTRICS_SPLIT,
  REWARD_HISTORY,
  RG_4306_RG_ROP_UPLOAD_STATUS,
  RG_7028_PROMO_LINK_EXPIRATION_HISTORY,
  RG_7080_TANGO_COMPANION_APP,
  RG_7198_REMOVE_403_IGNORE_LIST,
  RG_7282_TANGO_PRODUCT_OFFERINGS,
  RG_7482_TANGO_UTID_DIALOG,
  RG_7504_REISSUE_EXPIRED_PROMO_LINK_PERMISSION,
  RG_ACCOUNT_REDESIGN,
  RG_ACH_DRAW,
  RG_ACH_REDESIGN,
  RG_BULK_ORDER_LARGER_RAW_FILE_SIZE,
  RG_BULK_ORDER_LIMITS,
  RG_DOWNLOAD_LINE_ITEMS,
  RG_EASY_USE_CASE,
  RG_FUNDING_NOTIFICATIONS,
  RG_INCREASED_ORDER_HISTORY_PAGE_SIZE,
  RG_ORDER_DIALOG,
  RG_ORDER_HISTORY_TYPE,
  RG_PERSONALIZATION_TO_FROM_SEARCH,
  RG_RECIPE_ENGINE_ASYNC_ORDERING,
  RG_RECIPIENTS_WHITELIST_FEATURE,
  RG_SAML_SSO_UI,
  RG_SELF_SIGNUP,
  RG_SHOW_BULK_RESEND,
  RG_USE_LINE_ITEM_TABLE_FOR_SPECIFIC_ORDER,
  WYNDHAM_CUSTOM_FIELDS,
  WYNDHAM_OPTIN_MESSAGE,
  WYNDHAM_POPUP,
  RG_EDIT_CART_DIALOG,
  AA_212_SSO_CONNECTIONS,
  CHAMELEON_SPLIT,
  ACH_ACCOUNT_VALIDATION,
  AUTHENTICATED_SALESFORCE,
  RG_8006_CASE_MANAGEMENT_PERMISSION,
  AA_254_USER_MFA,
  RG_7991_SHOPPING_CART_INTEGRATION,
  SCHEDULED_DELIVERY,
  SCHEDULED_DELIVERY_BUTTON,
  OUT_OF_STOCK,
  INTEGRATION_CREDENTIALS,
  CASE_MANAGEMENT_ENABLE_MANAGE_PERMISSION,
  RG_8317_NEW_ORDERS_TABLE,
  QUALTRICS_NEW_TABLE,
  RG_8313_FUNDING_HISTORY_TABLE,
  RG_8310_NEW_REPORTS_TABLE,
  RG_8341_NEW_EMAIL_TEMPLATES_TABLE,
  RG_8284_INVOICE_ON_FUNDING_PAGE,
  RG_8374_NEW_EMAIL_TEMPLATE_HEADER_IMAGE,
  RECIPIENT_UPLOAD_MAPPER,
  RG_8280_RESCHEDULE_DELIVERY_TIME,
  RG_8402_SIGNUP_100K_TOGGLE,
  RG_8437_BULK_SHIPMENT,
  RECIPIENT_LIST_FIXES,
  ORDER_SUMMARY_DETAILS
} from './split-constants'

const TRAFFIC_TYPE_KEY = 'portalfrontend'
const TREATMENT_ON = 'on'

function initSplitClient (key) {
  const authKey = process.env.VUE_APP_SPLIT_API_KEY
  const factoryConfig = {
    core: {
      authorizationKey: authKey,
      key
    },
    startup: {
      readyTimeout: 1.5 // 1.5 sec
    }
  }

  if (authKey === 'localhost') {
    factoryConfig.features = LocalFeatures
  }

  const splitFactory = SplitFactory(factoryConfig)
  return splitFactory.client()
}

let userSplitClient
function createUserClient (userIdentityHash) {
  const splitClient = initSplitClient(userIdentityHash)
  return new Promise((resolve) => {
    splitClient.on(splitClient.Event.SDK_READY, () => {
      userSplitClient = splitClient
      resolve()
    })
  })
}

let platformSplitClient
function createPlatformClient (platformIdentityHash) {
  const splitClient = initSplitClient(platformIdentityHash)
  return new Promise((resolve) => {
    splitClient.on(splitClient.Event.SDK_READY, () => {
      platformSplitClient = splitClient
      resolve()
    })
  })
}

let applicationSplitClient
function createApplicationClient () {
  const splitClient = initSplitClient(TRAFFIC_TYPE_KEY)
  return new Promise((resolve) => {
    splitClient.on(splitClient.Event.SDK_READY, () => {
      applicationSplitClient = splitClient
      resolve()
    })
  })
}

function initApplicationSplitClient () {
  return Promise.all([createApplicationClient()]).then(() => {
    applyApplicationTreatments()
    store.dispatch(APP_SET_APPLICATION_SPLIT_IS_INITIALIZED, true)
  })
}

function initSplitClients (userIdentityHash, platformIdentityHash) {
  return Promise.all([createUserClient(userIdentityHash), createPlatformClient(platformIdentityHash), createApplicationClient()]).then(() => {
    applyPlatformTreatments()
    applyUserTreatments()
    applyApplicationTreatments()
    store.dispatch(APP_SET_APPLICATION_SPLIT_IS_INITIALIZED, true)
    store.dispatch(APP_SET_SPLIT_IS_INITIALIZED, true)
  })
}

const platformSplits = [
  { name: API_KEY_MANAGEMENT, enabledValue: 'on' },
  { name: OAUTH_CLIENT_CRED_MGMT, enabledValue: 'on' },
  { name: KBP_BRANDS_QR_CODE, enabledValue: 'on' },
  { name: KBP_BRANDS_BYPASS_EMAIL, enabledValue: 'on' },
  { name: ORDER_SETTINGS, enabledValue: 'on' },
  { name: POR_1968_RG_REFERRAL_PROGRAM, enabledValue: 'on' },
  { name: QUALTRICS_SPLIT, enabledValue: 'on' },
  { name: REWARD_HISTORY, enabledValue: 'on' },
  { name: RG_ACH_DRAW, enabledValue: 'on' },
  { name: RG_ACH_REDESIGN, enabledValue: 'on' },
  { name: RG_BULK_ORDER_LARGER_RAW_FILE_SIZE, enabledValue: 'on' },
  { name: RG_BULK_ORDER_LIMITS, enabledValue: 'on' },
  { name: RG_7504_REISSUE_EXPIRED_PROMO_LINK_PERMISSION, enabledValue: 'on' },
  { name: RG_DOWNLOAD_LINE_ITEMS, enabledValue: 'on' },
  { name: RG_FUNDING_NOTIFICATIONS, enabledValue: 'on' },
  { name: RG_ORDER_HISTORY_TYPE, enabledValue: 'on' },
  { name: RG_PERSONALIZATION_TO_FROM_SEARCH, enabledValue: 'on' },
  { name: RG_RECIPE_ENGINE_ASYNC_ORDERING, enabledValue: 'on' },
  { name: RG_RECIPIENTS_WHITELIST_FEATURE, enabledValue: 'on' },
  { name: RG_SAML_SSO_UI, enabledValue: 'on' },
  { name: WYNDHAM_CUSTOM_FIELDS, enabledValue: 'on' },
  { name: RG_SHOW_BULK_RESEND, enabledValue: 'on' },
  { name: RG_INCREASED_ORDER_HISTORY_PAGE_SIZE },
  { name: RG_ORDER_DIALOG, enabledValue: 'on' },
  { name: RG_7028_PROMO_LINK_EXPIRATION_HISTORY, enabledValue: 'on' },
  { name: RG_7080_TANGO_COMPANION_APP, enabledValue: 'on' },
  { name: RG_7198_REMOVE_403_IGNORE_LIST, enabledValue: 'on' },
  { name: RG_7282_TANGO_PRODUCT_OFFERINGS, enabledValue: 'on' },
  { name: RG_7482_TANGO_UTID_DIALOG, enabledValue: 'on' },
  { name: WYNDHAM_POPUP, enabledValue: 'on' },
  { name: WYNDHAM_OPTIN_MESSAGE, enabledValue: 'on' },
  { name: MOBILE_DELIVERY_MVP, enabledValue: 'on' },
  { name: ENABLE_CART_BRAND_VIEW, enabledValue: 'on' },
  { name: PORTAL_LINE_ITEMS_SHOW_REMAINING_BALANCE, enabledValue: 'on' },
  { name: RG_USE_LINE_ITEM_TABLE_FOR_SPECIFIC_ORDER, enabledValue: 'on' },
  { name: RG_EDIT_CART_DIALOG, enabledValue: 'on' },
  { name: RG_ACCOUNT_REDESIGN, enabledValue: 'on' },
  { name: AA_212_SSO_CONNECTIONS, enabledValue: 'on' },
  { name: CHAMELEON_SPLIT, enabledValue: 'on' },
  { name: AUTHENTICATED_SALESFORCE, enabledValue: 'on' },
  { name: ACH_ACCOUNT_VALIDATION, enabledValue: 'on' },
  { name: RG_8006_CASE_MANAGEMENT_PERMISSION, enabledValue: 'on' },
  { name: AA_254_USER_MFA, enabledValue: 'on' },
  { name: RG_7991_SHOPPING_CART_INTEGRATION, enabledValue: 'on' },
  { name: SCHEDULED_DELIVERY, enabledValue: 'on' },
  { name: SCHEDULED_DELIVERY_BUTTON, enabledValue: 'on' },
  { name: OUT_OF_STOCK, enabledValue: 'on' },
  { name: INTEGRATION_CREDENTIALS, enabledValue: 'on' },
  { name: CASE_MANAGEMENT_ENABLE_MANAGE_PERMISSION, enabledValue: 'on' },
  { name: RG_8317_NEW_ORDERS_TABLE, enabledValue: 'on' },
  { name: QUALTRICS_NEW_TABLE, enabledValue: 'on' },
  { name: RG_8313_FUNDING_HISTORY_TABLE, enabledValue: 'on' },
  { name: RG_8310_NEW_REPORTS_TABLE, enabledValue: 'on' },
  { name: RG_8341_NEW_EMAIL_TEMPLATES_TABLE, enabledValue: 'on' },
  { name: RG_8284_INVOICE_ON_FUNDING_PAGE, enabledValue: 'on' },
  { name: RG_8374_NEW_EMAIL_TEMPLATE_HEADER_IMAGE, enabledValue: 'on' },
  { name: RECIPIENT_UPLOAD_MAPPER, enabledValue: 'on' },
  { name: RG_8280_RESCHEDULE_DELIVERY_TIME, enabledValue: 'on' },
  { name: RG_8437_BULK_SHIPMENT, enabledValue: 'on' },
  { name: RECIPIENT_LIST_FIXES, enabledValue: 'on' },
  { name: ORDER_SUMMARY_DETAILS, enabledValue: 'on' }
]

const userSplits = []

const applicationSplits = [
  { name: RG_4306_RG_ROP_UPLOAD_STATUS, enabledValue: 'on' },
  { name: RG_SELF_SIGNUP, enabledValue: 'on' },
  { name: RG_EASY_USE_CASE, enabledValue: 'on' },
  { name: RG_8402_SIGNUP_100K_TOGGLE, enabledValue: 'on' }
]

function applyPlatformTreatments () {
  platformSplits.forEach((split) => {
    const treatment = platformSplitClient.getTreatment(split.name)
    store.dispatch(ADD_TREATMENT, { name: split.name, treatment, enabled: treatment === split.enabledValue })
  })
}

function applyUserTreatments () {
  userSplits.forEach((split) => {
    const treatment = userSplitClient.getTreatment(split.name)
    store.dispatch(ADD_TREATMENT, { name: split.name, treatment, enabled: treatment === split.enabledValue })
  })
}

function applyApplicationTreatments () {
  applicationSplits.forEach((split) => {
    const treatment = applicationSplitClient.getTreatment(split.name)
    store.dispatch(ADD_TREATMENT, { name: split.name, treatment, enabled: treatment === split.enabledValue })
  })
}

function getSplitTreatment (splitName) {
  return store.getters[GET_SINGLE_TREATMENT](splitName)?.treatment ?? 'control'
}

// Returns true if the provided split is enabled
// Optionally pass an array of splt names, and if any are enabled we'll return true
function isSplitEnabled (splitName) {
  if (splitName.constructor === Array) {
    // Returns true if any of the splits are enabled
    return splitName.some(isSplitEnabled)
  }
  return store.getters[GET_TREATMENT_ENABLED](splitName)
}

function isSplitEnabledWithAttributes (splitName, attributes) {
  // Our current implementation that caches splits in the store isn't set up for attributes so just call our split
  // client directly. This only works for platform splits - if we need application or user splits with attributes we can
  // expand it later.
  return platformSplitClient.getTreatment(splitName, attributes) === TREATMENT_ON
}

function SplitPlugin (Vue) {
  Vue.prototype.$split = {}
  Vue.prototype.$split.init = initSplitClients
  Vue.prototype.$split.getTreatment = getSplitTreatment
  Vue.prototype.$isSplitEnabled = isSplitEnabled
  Vue.prototype.$isSplitEnabledWithAttributes = isSplitEnabledWithAttributes
}

export {
  SplitPlugin,
  initSplitClients,
  initApplicationSplitClient,
  isSplitEnabled
}
