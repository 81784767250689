// Login
export const LOGIN_ORIGINAL_URL = 'loginOriginalUrl'
export const RG_LOGIN_METHOD = 'rgLoginMethod'
export const RG_LOGIN_EMAIL = 'rgLoginEmail'

// Platforms / Groups / Accounts
export const DEFAULT_CUSTOMER_ACCOUNT = 'defaultCustomerAccount'
export const LAST_SELECTED_CUSTOMER_ACCOUNT = 'lastSelectedCustomerAccount'
export const SELECTED_CUSTOMER_ACCOUNT = 'selectedCustomerAccount'

// Funding
export const LAST_USED_PAYMENT_SOURCE = 'lastUsedPaymentSource'

// Wyndham
export const LAST_SELECTED_WYNDHAM_LOCATION = 'lastSelectedWyndhamLocation'

// UI state
export const SIDEBAR_OPEN = 'sidebarOpen'
export const SHOW_SIDE_CONTAINER = 'showSideContainer'

export const UTID_DIALOG_VIEWED = 'utidDialogViewed'
// Used to set and get the last team settings path
export const TC_SETTINGS_PATH = 'TC_Settings_path'
export const TC_ACCOUNTS_NEW_PAGE = 'TC_ACCOUNTS_NEW_PAGE'

// Bulk Physical Order
export const BULK_PHYSICAL_ORDER_DETAILS = 'PORTAL_BULK_PHYSICAL_ORDER_DETAILS'
export const BULK_PHYSICAL_ORDER_IN_CART = 'PORTAL_BULK_PHYSICAL_ORDER_IN_CART'
